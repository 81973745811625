@font-face {
    font-family: Poppins;
    src: url(./fonts//Poppins-Bold.ttf);
    font-weight: 600;
    font-style: bold;
}

@font-face {
    font-family: Poppins;
    src: url(./fonts/Poppins-Medium.ttf);
    font-weight: normal;
    font-weight: 500;
}

@font-face {
    font-family: Poppins;
    src: url(./fonts/Poppins-Regular.ttf);
    font-weight: normal;
    font-weight: 400;
}

:root {
    --whiteColor: #fff;
    --greenColor: #005C30;
    --blackColor: #262A37;
    --paragraphColor: #716c80;
    --fontFamily: Poppins, Helvetica, Arial, sans-serif;
    --fontSize: 14px;
    --bs-body-line-height: 1.5;
}

html,
body {
    margin: 0;
    padding: 0;
    font-family: var(--fontFamily);
    font-size: var(--fontSize);
    line-height: var(--bs-body-line-height);
    font-weight: 400;
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
    color: var(--blackColor);
    font-weight: 600;
}

a {
    color: var(--blackColor) !important;
    font-weight: 500;
    text-decoration: none !important;
    outline: 0 !important;
}

a:hover {
    color: var(--greenColor) !important;
}

:focus {
    outline: 0 !important;
}

p {
    color: var(--paragraphColor);
    font-size: var(--fontSize);
    margin-bottom: 15px;
    line-height: 1.8;
}

p:last-child {
    margin-bottom: 0;
}

.mr-5 {
    margin-right: 5px !important;
}

.mr-10 {
    margin-right: 10px;
}

.mb-30 {
    margin-bottom: 30px;
}

.ptb-100 {
    padding-top: 100px;
    padding-bottom: 100px;
}

.ptb-50 {
    padding-top: 30px;
    padding-bottom: 50px;
}

.bg-F6F6F6 {
    background-color: #f7f7f8 !important;
}

.chat-bg {
    background-color: #F6F9FB;
    border-radius: 7px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, .06), 0 10px 50px rgba(0, 0, 0, .1);
    margin: 35px 0 25px;
}

.text-muted {
    color: var(--paragraphColor) !important
}

.text-success {
    color: var(--greenColor) !important
}

.container {
    width: 100%;
    margin: 0 auto;
    position: relative;
    max-width: 1140px !important;
    box-sizing: border-box;
}

/*btn*/
.default-btn {
    border: none;
    position: relative;
    padding: 9px 18px;
    display: inline-block;
    border-radius: 8px;
    font-size: var(--fontSize);
    background-color: #45A163;
    color: var(--whiteColor) !important;
    font-weight: 500;
}

.remove-btn {
    background: linear-gradient(44.44deg, #cb2d3e 7.79%, #ef473a 94.18%) !important;
}

.remove-btn:hover,
.remove-btn:focus {
    background: #cb2d3e !important;
}

.gray-btn {
    background: linear-gradient(44.44deg, #757F9A 7.79%, #8e9eab 94.18%);
}

.gray-btn:hover,
.gray-btn:focus {
    background: #757F9A !important;
}

.light-green-btn {
    background: rgba(0, 152, 104, 0.1);
    border: 1px solid var(--borderColor) !important;
    color: #009868 !important;
    padding: 5px 18px;
    border-radius: 6px;
    font-size: 13px;
    font-weight: 700;
}

.light-green-btn:hover,
.light-green-btn:focus {
    background: #2A875C;
    color: var(--whiteColor) !important;
}

.graybutton {
    background-color: #eaeaea !important;
    border: 1px solid #eaeaea !important;
    color: #262a2e !important;
}

/*section-title*/

.section-title .sub-title {
    margin-bottom: 15px;
    font-weight: 500;
    color: var(--blackColor);
    line-height: 1.2;
}

.section-title h2 {
    font-size: 27px !important;
    line-height: 1.4 !important;
    margin-bottom: 10px;
}

.fs-14 {
    font-size: 14px !important;
}

.font-medium {
    font-weight: 500;
}

/* logo */
.main-logo-title {
    line-height: 1.4 !important;
    font-weight: 600 !important;
    color: var(--blackColor);
}

.logo-heading {
    font-size: 30px !important;
    margin-bottom: 5px !important;
}

.mini-logo-title {
    font-size: 20px !important;
}

.slogan-heading {
    font-size: 16px;
    line-height: 1.8;
    color: var(--paragraphColor);
    margin-bottom: 10px;
    font-weight: 500 !important;
}

.label-badge {
    font-weight: 500;
    margin: 20px 0;
    font-size: 15px !important;
    background: linear-gradient(to right, #1E72A5, #005C30);
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.text-gradient {
    background: linear-gradient(to right, #1E72A5, #005C30);
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.language-badge {
    font-size: 15px;
    display: inline-block;
    font-weight: 700;
    letter-spacing: 0.1em;
    background: linear-gradient(to right, #1E72A5, #005C30);
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.pe-none {
    opacity: .65 !important;
}

/* toast */
.Toastify__toast {
    min-height: 64px !important;
    padding: 15px !important;
}

.Toastify__toast-body {
    margin: 0 !important;
}

.Toastify__toast--success {
    font-size: var(--fontSize) !important;
    border-radius: 15px !important;
    background: #6e9d75 !important;
    color: var(--whiteColor) !important
}

.Toastify__toast--error {
    font-size: var(--fontSize) !important;
    border-radius: 15px !important;
    background: #c52965 !important;
    color: var(--whiteColor) !important
}

.Toastify__toast--success .Toastify__close-button,
.Toastify__toast--error .Toastify__close-button {
    color: var(--whiteColor) !important;
    width: 21px;
    height: 21px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 100 !important;
}

.Toastify__toast--success .Toastify--animate-icon svg,
.Toastify__toast--error .Toastify--animate-icon svg {
    fill: var(--whiteColor);
}

/* modal */
.modal {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal-content {
    background: var(--whiteColor);
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    font-size: 12px;
    font-weight: 500;
    height: 148px;
    justify-content: flex-end;
    width: 373px;
}

.modal-content p {
    font-size: 14px !important;
    margin-bottom: 38px;
    color: var(--blackColor);
    text-align: center;
}

.modal-buttons {
    border-top: 1px solid #e8ecf3;
    display: flex;
}

button.modal-buttons__button {
    height: 51px;
    width: 100%;
    border: 0;
    background-color: transparent;
}

button.modal-buttons__button:first-child {
    border-right: 1px solid #e8ecf3;
    color: #f13636;
}

.mobile-app-modal {
    padding: 16px 12px;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 4px 8px 0px;
    background-color: #F6F6F6 !important;
}

.appmodal-offcanvas {
    position: fixed !important;
    bottom: -1px !important;
    z-index: 1050 !important;
    width: 100% !important;
    outline: 0 !important;
    margin: 0 !important;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-color: var(--whiteColor) !important;
    border-width: 0 !important;
}

.mobile-app-modal-header {
    text-align: center;
    padding-bottom: 14px;
    margin-bottom: 8px;
    font-size: 18px;
    line-height: 24px;
    font-weight: 600;
}

.mobile-app-modal-imgWrapper {
    box-sizing: border-box;
    width: 48px;
    height: 48px;
    padding: 5px;
    border: 1px solid rgb(241, 241, 244);
    border-radius: 3px;
}

.mobile-app-modal-imgWrapper img {
    height: 36px
}

.mobile-app-modal-text {
    padding-left: 8px;
    font-size: 15px;
    line-height: 24px;
    color: var(--blackColor);
}

.mobile-app-modal button {
    width: 115px;
}

.lang-modal-content {
    width: 500px;
    max-width: 500px;
    height: unset;
}

.w-12 {
    width: 3.5rem !important;
}

/* chatbot */

.chatbox {
    padding: 20px;
    display: flex;
    flex-direction: column;
}

.chatbox .chat {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.chat-left {
    background-color: rgba(0, 152, 104, 0.1) !important;
    color: #009868 !important;
    font-weight: 500;
}

.chatbox img {
    width: 20px;
    height: 20px;
    margin-right: 15px;
}

.chatbox img.right {
    margin-left: 15px;
    margin-right: 0;
}

.chatbox .msg {
    max-width: 75%;
    background-color: var(--whiteColor);
    border-radius: 7px;
    color: #24292e;
    font-size: var(--fontSize) !important;
    padding: 8px 15px !important;
    text-align: left !important;
    line-height: 1.8;
    border: 1px solid rgb(231, 231, 231);
}

.chatbox .chat:nth-child(odd) {
    flex-direction: row-reverse;
}

/*form-control*/
.form-control {
    box-shadow: 20px 20px 54px rgba(0, 0, 0, 0.03);
    font-size: var(--fontSize);
    color: var(--blackColor) !important;
    border: 1px solid rgb(215 215 206/1);
    padding-left: 15px;
    border-radius: 10px;
    height: 55px;
}

.form-control::-moz-placeholder {
    color: var(--paragraphColor);
}

.form-control::placeholder {
    color: var(--paragraphColor);
}

.form-control:focus {
    outline: 2px solid transparent;
    outline-offset: 2px;
    box-shadow: 20px 20px 54px rgba(0, 0, 0, 0.03) !important
}

.form-control.error-border:focus {
    border-color: #a92e2d !important;
}

.textAi-input {
    width: 600px;
    margin-top: 20px;
    border-radius: 14px !important;
    color: var(--blackColor) !important;
    cursor: pointer;
    font-size: 16px;
    height: 64px;
    margin-bottom: 20px;
    padding: 2px 0 2px 2px;
    text-align: center;
    background-color: #fafafa !important;
    position: relative;
}

.textAi-input:before {
    animation: ai_spin__37ueH 5s linear infinite;
    background-image: linear-gradient(var(--rotate), #3468C0, #6D9886 43%, #86A7FC);
    border-radius: 16px;
    content: "";
    inset: -3px;
    position: absolute;
    z-index: -1
}

.textAi-input:after {
    animation: ai_spin__37ueH 6s linear infinite;
    background-image: conic-gradient(from var(--rotate), #3468C0, #6D9886, #86A7FC, #6D9886, #3468C0);
    content: "";
    filter: blur(12.8px);
    inset: -6px -60px;
    opacity: 1;
    position: absolute;
    transform: scale(.84);
    transition: opacity .8s;
    z-index: -1
}

.textAi-input textarea {
    background: none;
    border: none;
    box-sizing: border-box;
    height: 100%;
    outline: none;
    padding: 13px 15px;
    width: 100%;
    resize: none;
}

@keyframes ai_spin__37ueH {
    0% {
        --rotate: 0deg
    }

    25% {
        --rotate: 150deg
    }

    50% {
        --rotate: 180deg
    }

    75% {
        --rotate: 240deg
    }

    to {
        --rotate: 360deg
    }
}

.description {
    white-space: pre-wrap;
}

.animate-typing .dot {
    width: 7px;
    height: 7px;
    background-color: #495057;
    border-radius: 50%;
    margin: 0 2px;
    display: inline-block;
}

.popover-body {
    padding: 0.6rem 0.8rem !important;
    color: #495057 !important;
    line-height: var(--bs-body-line-height);
    background-color: var(--whiteColor);
    border: 1px solid rgba(0, 0, 0, .1);
    border-radius: 0.4rem;
}

.loader-line {
    height: 28px;
    display: flex;
    align-items: center;
}

.line {
    display: inline-block;
    width: 4px;
    height: 10px;
    border-radius: var(--fontSize);
    background-color: rgba(121, 124, 140, .7);
    margin: 0 2px;
}

/* example */
.example-info-panel h3 {
    font-size: 16px !important;
    font-weight: 500 !important;
    margin-bottom: 15px;
}

/* card */
.example-info-panel .card {
    background-color: #f8f9fa;
    border: 1px solid #eee;
    margin: 8px;
    padding: 6px 12px;
    border-radius: 50px 50px 50px 0;
    justify-content: center;
    cursor: pointer;
    transition: none .15s ease-in-out;
}

.example-info-panel span {
    color: var(--paragraphColor);
    font-size: 13px !important;
    font-weight: 500;
}

.height-65 {
    height: 70px;
}

.height-40 {
    height: 55px;
}

.h-md-50 {
    height: 70px;
}

.example-info-panel .card:hover {
    transform: scale(1.03);
    background-color: #e9ecef;
}

audio {
    -moz-box-shadow: 0 2px 4px rgba(15, 34, 58, .12);
    -webkit-box-shadow: 0 2px 4px rgba(15, 34, 58, .12);
    box-shadow: 0 2px 4px rgba(15, 34, 58, .12);
    -moz-border-radius: 6px;
    -webkit-border-radius: 6px;
    border-radius: 6px;
}

.error {
    background: #ffefef;
    border-radius: 8px;
    color: #a92e2d;
    font-size: 13px;
    font-weight: 500;
    margin-bottom: 20px;
    padding: 6px 12px;
    text-align: left !important;
    font-weight: 500;
}

.right-inner-addon-icon {
    position: absolute;
    right: 10px;
    top: 19px;
}

/* 404 */

.authincation {
    background: #f0f0f8;
    display: flex;
    min-height: 100vh;
}

.error-page .dz-error {
    position: relative;
    color: black;
    font-size: 150px;
    letter-spacing: 0px;
    font-weight: 900;
    line-height: 150px;
    margin: auto;
    font-weight: 600 !important;
}

.error-page .error-head {
    color: var(--whiteColor);
    margin-bottom: 18px;
    font-size: 20px;
    line-height: 1.4;
    font-weight: 500;
    color: #60697b;
    font-weight: 600 !important;
}

.min-vh-80 {
    height: 70vh;
}

.contact-area .section-title {
    text-align: center;
    max-width: 660px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 55px;
}

.contact-form {
    max-width: 950px;
    margin-left: auto;
    margin-right: auto;
}

.contact-form label {
    margin-bottom: 5px;
}

.spinner-border {
    --bs-spinner-width: 2rem;
    --bs-spinner-height: 2rem;
    --bs-spinner-vertical-align: -0.125em;
    --bs-spinner-border-width: 0.25em;
    --bs-spinner-animation-speed: 0.75s;
    --bs-spinner-animation-name: spinner-border;
    border: var(--bs-spinner-border-width) solid currentColor;
    border-right-color: transparent;
    display: inline-block;
    width: var(--bs-spinner-width);
    height: var(--bs-spinner-height);
    vertical-align: var(--bs-spinner-vertical-align);
    border-radius: 50%;
    animation: var(--bs-spinner-animation-speed) linear infinite var(--bs-spinner-animation-name);
}

.border-bottom {
    border-color: #eaeaea !important;
}

.textarea-custom-scroll {
    border: 1px solid #ccc;
    overflow: auto;
    scrollbar-width: auto;
    scrollbar-color: #888 #ddd;
    padding-right: 28px;
}

.textarea-custom-scroll::-webkit-scrollbar {
    width: 5px;
}

.textarea-custom-scroll::-webkit-scrollbar-thumb {
    background-color: #888;
}

.textarea-custom-scroll::-webkit-scrollbar-thumb:hover {
    background-color: #555;
}

.list-group {
    border: 1px solid #eeeeee;
    margin-bottom: 20px;
}

/* news */
.news-content h4 {
    font-size: 20px !important;
    margin-bottom: 8px;
}

.news-content p {
    margin-bottom: 20px;
}

.news-thumb {
    margin-right: 25px;
    max-width: 100%;
    overflow: hidden;
    align-self: center !important;
}

.news-thumb img {
    width: 100px;
    height: 75px;
    object-fit: contain;
}

.news-cat {
    font-size: 12px;
    display: inline-block;
    font-weight: 600;
    color: var(--greenColor);
}

.news-title {
    font-size: var(--fontSize) !important;
    line-height: var(--bs-body-line-height) !important;
    margin: 5px 0 10px !important;
    font-weight: 500;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 43px;
}

.media-body {
    -ms-flex: 1;
    flex: 1;
}

.news-date {
    color: var(--paragraphColor);
}

.news-date span {
    font-size: 12px;
    line-height: 0;
    display: inline-block;
}

/* footer */
.footer-area {
    border-top: 1px solid rgba(69, 47, 244, 0.1);
    padding-top: 28px;
}

.footer-widget {
    display: flex;
    justify-content: space-between;
}

.footer-area .logo {
    margin-bottom: 15px !important;
    display: flex;
    align-items: center;
}

.footer-area .logo img {
    width: 2.4rem;
    margin-right: 0;
}

.footer-area .title {
    font-size: 16px !important;
    font-weight: 600;
    margin-bottom: 0 !important;
}

.footer-area h3 {
    font-size: 16px !important;
    font-weight: 600;
    margin-bottom: 15px !important;
}

.footer-area p {
    margin-bottom: 8px;
    line-height: 1.8;
    color: #343a40;
    font-size: var(--fontSize);
    max-width: 380px;
}

.footer-area .social-links {
    padding-left: 0;
    list-style-type: none;
    margin-top: 20px;
    margin-bottom: 0;
}

.footer-area.social-links li:first-child {
    margin-left: 0;
}

.footer-area .social-links li {
    display: inline-block;
    text-align: center;
    margin-left: 3px;
    margin-right: 3px;
}

.footer-area .social-links li a {
    color: #24292e !important;
    line-height: 35px;
    display: block;
    font-size: 18px;
    z-index: 1;
    margin-right: 8px;
}

.footer-area .links-list {
    padding-left: 0;
    margin-bottom: 0;
    list-style-type: none;
}

.footer-area .links-list li {
    margin-bottom: 10px;
}

.footer-area .links-list li a {
    display: inline-block;
    color: #343a40 !important;
    font-size: var(--fontSize);
}

.footer-area .links-list li a:hover {
    color: var(--greenColor) !important;
}

.download-app a img {
    width: 110px;
    border-radius: 5px;
}

.pl-80 {
    padding-left: 80px;
}

.copyright-area {
    border-top: 1px solid rgba(69, 47, 244, 0.1);
    text-align: center;
    margin-top: 18px;
    padding-top: 18px;
    padding-bottom: 8px;
    display: flex;
    justify-content: center;
}

.copyright-area p {
    margin-bottom: 15px;
    line-height: 1.8;
    color: #343a40;
    font-size: var(--fontSize);
}

.privacy-policy-content p,
.terms-conditions-content p,
.about-content p {
    color: #343a40 !important;
}

.privacy-policy-content h6,
.terms-conditions-content h6,
.about-content h3 {
    font-size: 20px !important;
    margin-bottom: 15px;
}

.privacy-policy-content h6:not(:first-child),
.terms-conditions-content h6:not(:first-child) {
    margin-top: 25px;
}

.terms-conditions-content strong,
.privacy-policy-content strong {
    color: var(--blackColor) !important;
}

.privacy-policy-content h5 {
    font-size: 16px !important;
    margin-bottom: 15px;
}

.privacy-policy-content ul {
    padding-left: 0;
    list-style-type: none;
    margin-bottom: 0;
}

.privacy-policy-content li {
    color: var(--paragraphColor);
    margin-bottom: 10px;
    position: relative;
    font-size: 15px;
    padding-left: 22px;
    display: flex;
    align-items: center;
}

.privacy-policy-content li svg {
    left: -2px;
    color: var(--paragraphColor);
    position: absolute;
}

.single-info-box {
    margin-bottom: 20px;
    padding: 24px;
    background-color: #f9f9f9;
    border-radius: 16px;
    display: flex;
    justify-content: space-between;
    height: 85%;
    align-items: center
}

.mr-24px {
    margin-right: 24px;
}

.single-info-box .image img {
    display: inline-block;
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 80px;
    object-fit: contain;
}

.single-info-box h3 {
    line-height: 1.5;
    margin-bottom: 8px !important;
    font-size: 18px !important;
    font-weight: 600 !important;
    color: var(--blackColor) !important;
}

.single-info-box p {
    color: var(--paragraphColor) !important;
    font-size: var(--fontSize)
}

@media (max-width: 600px) {
    .info .row {
        margin: 0 !important;
    }

    .info .row>* {
        padding: 0 !important;
    }

    .error-page .dz-error {
        font-size: 80px !important;
        line-height: 80px !important;
        letter-spacing: 5px !important;
    }

    .error-page .error-head {
        font-size: 20px !important;
        margin-bottom: 18px !important;
    }
}

@media (min-width: 992px) and (max-width: 1199px) {

    .section-title h2 {
        font-size: 24px !important;
    }

    .section-title p {
        font-size: 11px !important;
    }

    .container {
        padding: 0 25px;
    }
}

@media (min-width: 768px) and (max-width: 991px) {

    .section-title h2 {
        font-size: 25px !important;
    }

    .section-title p,
    .footer-area p,
    .footer-area .links-list li a {
        font-size: 13px !important;
    }

    .footer-area h3 {
        font-size: 14px !important;
    }
}

@media (max-width: 767px) {

    .slogan-heading {
        font-size: 15px;
    }

    .logo-heading {
        font-size: 25px !important;
    }

    .default-btn {
        padding: 7px 14px;
    }

    .label-badge {
        margin: 15px 0;
        font-size: 14px !important;
    }

    .chatbox {
        display: flex;
        flex-direction: column;
        padding: 12px;
    }

    .chatbox .chat {
        margin-bottom: 15px !important;
    }

    .chatbox .msg {
        padding: 10px 10px !important;
        max-width: 80% !important;
        font-size: 13px !important;
    }

    .example-info-panel .row {
        padding: 0 !important;
        margin: 0 !important;
    }

    .example-info-panel .row>* {
        padding: 0 !important;
    }

    .bg-chat-light {
        border-radius: 6px !important;
    }

    .textAi-input {
        width: 100% !important;
    }

    .example-info-panel .card {
        border-radius: 10px !important;
        transition: unset !important;
        margin: 0 0 15px !important;
    }

    .example-info-panel .main-card {
        box-shadow: 0px 2px 4px 0px rgba(21, 21, 26, 0.06);
        box-sizing: border-box;
        border: 1px solid rgb(231, 231, 231);
        border-radius: 6px;
        flex-direction: column;
    }

    .example-info-panel .card-header {
        color: #212529;
        margin: 0;
        padding: 1em;
        font-size: 1.05em;
        font-weight: 500;
        text-align: left;
        border-bottom: 1px solid #f1f3f5 !important;
    }

    .example-info-panel .card-body {
        margin-top: 1em;
        background-color: #f8f9fa;
        border: 1px solid #eee;
        padding: 6px 12px;
        border-radius: 10px;
        justify-content: center;
    }

    .example-info-panel span {
        font-size: 12px !important;
    }

    .h-md-50,
    .height-40 {
        height: unset !important;
    }

    .scrolled {
        left: 0;
        position: fixed;
        top: 0;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        z-index: 1;
        background-color: #f8fafb;
        align-items: center;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        padding: 6px 0 8px;
    }

    .qus-title h2 {
        font-size: 13px !important;
        color: #505050;
        margin-bottom: 0 !important;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        white-space: pre-wrap;
        line-height: 25px !important;
    }

    .example-info-panel h3 {
        font-size: 14px !important;
    }

    .section-title h2,
    .news-content h4,
    .privacy-policy-content h6, .terms-conditions-content .h6,
    .about-content h3,
    .single-info-box h3 {
        font-size: 18px !important;
    }

    .section-title p,
    p,
    .single-info-box p {
        font-size: 13px !important;
    }

    .footer-widget {
        margin-bottom: 20px !important;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        justify-content: flex-start;
    }

    .pl-80 {
        padding-left: 0;
        margin-right: 50px;
    }

    .copyright-area {
        display: block;
        padding-bottom: 10px !important;
        margin-top: 0 !important;
    }

    .footer-area p {
        margin-bottom: 0 !important;
        padding-right: 0;
        font-size: 13px;
    }

    .footer-area .social-links {
        margin-top: 10px;
    }

    .footer-area h3 {
        font-size: 16px !important;
    }

    .footer-area .links-list li a {
        font-size: 13px !important;
    }

    .news-title {
        font-size: 13px !important;
        height: 38px;
    }

    .textAi-input:after {
        inset: -6px -35px;
    }

    .single-info-box {
        height: unset !important;
    }

    .single-info-box .image {
        display: none;
    }
}

@media (max-width: 700px) {
    .container {
        padding: 0 15px;
    }
}